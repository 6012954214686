footer {
  /* background:#FF8FB1; */
  position: fixed;
  bottom: 0;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  justify-content: center;
}

.container ul li {
  margin: 0px;
}

.container ul {
  display: flex;
  gap: 3rem;
}

.container ul li {
  font-size: 40px;
}

@media (max-width: 950px) {
  .container ul li {
    font-size: 25px;
  }
}

@media (max-width: 450px){
  footer { 
    position: fixed;
    bottom:0px;
  }
  
} 

.icon{
  cursor: pointer;
  transition: all .4s;
  border-radius: 50px;
}

.icon:nth-child(1):hover {
  box-shadow: 0px 0px 0px 10px #1877f2;
}

.icon:nth-child(2):hover {
  box-shadow: 0px 0px 0px 10px white;
}

.icon:nth-child(3):hover {
  box-shadow: 0px 0px 0px 10px #25d366;
}

.icon:nth-child(4):hover {
  box-shadow: 0px 0px 0px 10px #1da1f2;
}

.icon:nth-child(5):hover {
  box-shadow: 0px 0px 0px 10px white;
}

.fa-github{
  color:white;
  filter:drop-shadow(0px 0px 10px white)
}

.fa-facebook{
  color:#1877f2;
  filter:drop-shadow(0px 0px 10px #1877f2)
}

.fa-linkedin{
  color:white;
  filter:drop-shadow(0px 0px 10px white)
}

.fa-whatsapp{
  color: #25d366;
  filter:drop-shadow(0px 0px 10px #25d366)
}

.fa-twitter{
  color:#1da1f2;
  filter:drop-shadow(0px 0px 10px #1da1f2)
}