*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Quicksand', sans-serif;
    outline: none;
    border: none;
}

.nav{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    height: 4rem;
    width: 100%;
}

.call1{
    display: none;
}

.openMenu{
    display: none;
}

.left p{
    color: white;
}

.vertical{
    display: flex;
    align-items: center;
    padding-right: 2rem;
}
ul{
    display: flex;
}
li{
    list-style: none;
    margin-right: 6rem;
}
li a{
    text-decoration: none;
    text-transform: capitalize;
    color: white;
}
li a:hover{
    color: rgb(213, 127, 127);
}


.menu{
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 17rem;
    background: #fff;
    z-index: 10000;
    background: lightgray;
    display: flex;
    flex-direction: column;
}
.menu.active{
    left: 0;
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.7);
}




.menu_top{
    background: white;
    border-radius: 20px;
    width: 16rem;
    height: 2rem;
    padding-top: .4rem;
    margin-top: 2rem;
    margin-left: .5rem;
}
.menu_top i{
    padding-left: 1rem;
}
.menu_top input{
    width: 80%;
    font-size: 1rem;
    padding-left: .5rem;
}

.menu_center{
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    margin-top: 4rem;
}
.menu_center a{
    text-decoration: none;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 3rem;
}
.menu_center a i{
    margin-right: 5px;
    color: #333;
}
.menu_center a:hover{
    color: lightcoral;
    scale: .5;
}

@media(max-width: 940px) {

    .vertical li:target{
        display: block;
    }

    .vertical ul{
        display: none;
    }
    

    .openMenu{
        margin-top: 0.7rem;
        display: block;
    }
 
    
    .column ul{
    justify-content: space-evenly;
    top: 5em;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(100px);
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
    transition: all 0.5s ease;
    }
    li{
        max-width: 6rem;
        margin: 30px 0;
        font-size: 1.2em;
    }

}

.nav-link {
    text-decoration: none;
    padding: 10px;
  }
  
  .active-link {
    color: red;
    font-weight: bold;
  }

@media (max-width: 450px){
    .call1{
      display: block;
      margin-top: 0.5rem;
    }
    .call2{
        display: none;
    }
}

