

:root{
  background-color:  #0f0f0f;
 
  
}
*{
  box-sizing: border-box;
}
