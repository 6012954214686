

.principal{
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    height: 70vh;
}

.moi{
    display: flex;
    justify-content: center;
    color: white;
    width: 50%;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 60px;
    align-items: center;
    
}

.titre{
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.titre h1{
    color: yellowgreen ;
    letter-spacing: 4px;
    
}

.defile{
    display: flex;
    overflow: hidden;
    width: 50%;
    position: absolute;
    /* transform: rotate(90deg); */
}

.txt{
    white-space: nowrap;
    font-size: 80px;
    animation: scrollTxt 10s linear infinite;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.t1{
    color:#999999;
}

.t2{
    color: #999999;
}

@keyframes scrollTxt {
    0%{
        transform: translate(100%,50);
    }
    100%{
        transform: translate(-100%,0);
    }
}

.image {
    width: 50%;
   display: flex;
   justify-content: center;
}

.image img{
    width: 50%;
    margin-top: 10%;
    margin-bottom: 10%;
}

.boutton{
    margin-top: 5rem;
    display:flex ;
    width: 100%;
    justify-content: center;
}

.boutton a{
    position: relative;
    padding: 25px 30px;
    margin: 40px 0; color: #03e9f4;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s; letter-spacing: 4px;
    overflow: hidden;
}

.boutton a:hover{
    background: #03e9f4; color: #050801;
}

a span{
    position: absolute;
    display: block;
}

a span:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#03e9f4);
    animation: animationUne 1s linear infinite;
}

@keyframes animationUne{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}

a span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#03e9f4);
    animation: animationDeux 1s linear infinite;
    animation-delay:0.25s ;
}

@keyframes animationDeux{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}

a span:nth-child(3){
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#03e9f4);
    animation: animationTrois 1s linear infinite;
    animation-delay:0.50s ;
}

@keyframes animationTrois{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}

a span:nth-child(4){
    bottom: 100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg,transparent,#03e9f4);
    animation: animationQuatre 1s linear infinite;
    animation-delay:0.75s ;
}

@keyframes animationQuatre{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100%;
    }
}


@media (max-width: 900px){
    .principal{
        display: flex;
        flex-direction: column-reverse; 
        justify-content: center;
    }

    .image {
       display: flex;
       justify-content: center;
       width: 100%;
    }
    
    .image img{
       width: 100%;
       margin-left: 3rem;
       margin-right: 3rem;
    }
    
    .moi{
        display: flex;
        justify-content: center;
        color: white;
        width: 100%;
        
    }
    .defile{
        width: 100%;
    }
    
}

@media (max-width: 900px){
    .image img{
        width: 100%;
       padding: 20px;
       margin-top: 0px;
       padding-bottom: 0px;
     }

     .moi{
        display: flex;
        justify-content: center;
        color: white;
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 1rem;
    }
    
    .titre {
        width: 100%;
    }

    .titre h1{
      font-size: 100%;
    }

    .titre p{
        font-size: 80%;
       margin-left: 5%;
       margin-left: 5%;
    }

    
    .boutton{
        margin-top: 0rem;
        display:flex ;
        width: 100%;
        justify-content: center;
       
    }

    .txt{
        font-size: 30px;
    }
    .defile{
        width: 100%;
    }
    
    
}
