.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5% auto;
  padding: 2rem;
  border-radius: 8px;
  background: hwb(210 36% 53%);
  color: white;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.contact h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form .MuiTextField-root {
  margin-bottom: 1rem;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

@media (max-width: 634px) {
  .contact {
    width: 90%;
  }
}
