#principale {
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  background: linear-gradient(135deg, #74ebd5, #acb6e5);
}

.projet {
  display: flex;
  justify-content: center;
  width: 60%;
  align-items: center;
  animation: fadeInUp 1s ease-out;
}

.card {
  display: flex;
  flex-direction: column;
  height: 40em;
  width: 30em;
  color: #333;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.div_image {
  height: 60%;
  width: 100%;
  overflow: hidden;
}

.div_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.div_image img:hover {
  transform: scale(1.1);
}

.div_contenu {
  padding: 1.5rem;
  text-align: center;
}

.titre {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.stars {
  margin-bottom: 1rem;
}

.stars i {
  color: gold;
  margin: 0 2px;
}

.description {
  margin-bottom: 1.5rem;
}

.description p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.bouton {
  margin-top: 1rem;
}

button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.iconleft i,
.iconright i {
  color: white;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s;
}

.iconleft i:hover,
.iconright i:hover {
  color: #007bff;
}

.iconleft,
.iconright {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#slide {
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.div_image {
  height: 70%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 955px) {
  .card {
    width: 22em;
    height: 35em;
  }
}

@media (max-width: 600px) {
  .card {
    width: 18em;
    height: 30em;
  }

  .titre {
    font-size: 1.2rem;
  }

  .description p {
    font-size: 0.9rem;
  }

  button {
    padding: 10px 18px;
  }
}
