body {
    font-family: 'Arial', sans-serif;
    background-color: #2a2a2a;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

.about-section {
    padding: 10px 0;
    background: linear-gradient(to right, #1a1a1a, #333333);
}

.container {
    width: 100%;
    margin: none;
    display: flex;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
}

.left-row{
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: start;
    margin-left: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    width: 50%;
    display: flex;
    align-items: flex-start;
    padding-left: 3rem;
    gap: 30px;
}

.section-title {
    font-size: 2.5em;
    color: rgb(130, 165, 200);
    text-transform: uppercase;
}

.profile-pic{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-me{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-pic{
    width: 100%;
}

.profile-pic img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    /* border: 5px solid rgb(130, 165, 200); */
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); */
}

.text {
    max-width: 600px;
    text-align: left;
}

.subtitle {
    margin-top: 10px;
    font-size: 1.8em;
    color: rgb(130, 165, 200);
    margin-bottom: 15px;
}

.description {
    font-size: 0.8em;
    /* margin-bottom: 30px; */
    line-height: 1;
    font-size: 15px;  
    line-height: 1.3;
    color: #778284;
   text-align: center;
}

.skills {
    margin-bottom: 30px;
}

.skills h4 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: rgb(130, 165, 200);
}

.skills-group {
    margin-bottom: 20px;
}

.skills-group h5 {
    font-size: 1.2em;
    color: rgb(130, 165, 200);
}

.skills ul {
    list-style: none;
    padding: 0;
    gap: 2rem;
}

.skills ul li {
    background: #444;
    padding: 10px;
    /* margin-bottom: 10px; */
    border-radius: 5px;
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 928px) {

    .container {
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .left-row{
        width: 100%;
        margin-left: 1rem;
        flex-direction: column;
    }
    .content {
        width: 100%;
        padding-left: 2rem;
        gap: 30px;
    }

    .about-section {
        padding: 0;
    }

    .skills ul {
        list-style: none;
        padding: 0;
        gap: 5px;
    }

    .content {
        flex-direction: column;
        align-items: center;
    }
    .profile-pic img {
        width: 200px;
        height: 200px;
    }
    .section-title {
        font-size: 2em;
    }
}

@media (max-width: 1000px) {

    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .left-row{
        width: 100%;
        justify-content: start;
        flex-direction: column;
    }
    .content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 2rem;
        gap: 30px;
    }

    .about-section {
        padding: 0;
    }

    .skills ul {
        list-style: none;
        padding: 0;
        gap: 5px;
    }

    .content {
        flex-direction: column;
        align-items: center;
    }
    .profile-pic img {
        width: 200px;
        height: 200px;
    }
    .section-title {
        font-size: 2em;
    }
}
